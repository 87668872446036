import React, { useEffect } from 'react';

const CalendlyButton = () => {
  useEffect(() => {
    // Add Calendly CSS
    const link = document.createElement('link');
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Add Calendly script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Initialize Calendly widget
    script.onload = () => {
      window.Calendly.initBadgeWidget({
        url: 'https://calendly.com/sayedbw/30min?hide_gdpr_banner=1',
        text: '📞',
        color: '#ea580c',
        textColor: '#ffffff',
        branding: undefined
      });

      // Apply custom styles to make the button circular and ensure visibility
      const style = document.createElement('style');
      style.textContent = `
        .calendly-badge-widget {
          right: 20px !important;
          bottom: 20px !important;
        }
        .calendly-badge-widget .calendly-badge-content {
          width: 72px !important;
          height: 72px !important;
          padding: 0 !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          border-radius: 50% !important;
          font-size: 36px !important;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25) !important;
        }
      `;
      document.head.appendChild(style);
    };

    return () => {
      // Cleanup
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default CalendlyButton;
