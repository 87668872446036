import React from 'react';
import { useState, useEffect, useCallback } from 'react'
import { Mail, Zap, Shield, ArrowRight, ChevronLeft, ChevronRight, Briefcase, Users, DollarSign, Building, Calendar, TrendingUp, ArrowUp, Check, Star, Play, Sun } from 'lucide-react'
import CalendlyButton from './components/CalendlyButton';
import './App.css';

const testimonials = [
  {
    id: 1,
    name: "Michael Rose",
    company: "Michael Rose & Associates",
    quote: "The Email Protocol helped us increase our monthly email send volume by 400% and boost our closed deals by 300%.",
    caseStudyLink: "https://email-protocol-case-stud-j8veye9.gamma.site/",
    image: "/images/michael-rose.jpg",
    backgroundImage: "/images/testimonial-bg1.jpg",
    icon: DollarSign
  },
  {
    id: 2,
    name: "Karim Kabir",
    company: "Sunray Energy Solutions",
    quote: "The Email Protocol helped us achieve a 150% increase in qualified leads and double our lead-to-customer conversion rate.",
    caseStudyLink: "https://solarbright-case-study-ycpvlhl.gamma.site/",
    image: "/images/karim-kabir.jpg",
    backgroundImage: "/images/testimonial-bg2.jpg",
    icon: Sun
  }
]

const whyUsData = [
  { 
    icon: Mail, 
    title: "Cold Email Specialists", 
    content: "100% focus on B2B cold email\nWe exclusively do cold email and related tasks\nTailored strategies for established businesses"
  },
  { 
    icon: Users, 
    title: "Selective Partnerships", 
    content: "We work exclusively with:\nB2B companies with proven case studies\nBusinesses that have achieved product-market fit"
  },
  { 
    icon: Briefcase, 
    title: "Proven Expertise", 
    content: "Deep understanding of business dynamics\nContinuous study of email technology advancements\nApplying psychological principles to optimize outreach"
  },
  { 
    icon: Zap, 
    title: "Accelerated Growth Timeline", 
    content: "Day 1: Comprehensive onboarding\nBy Day 3: Complete system setup\nDays 7-10: Start generating meetings"
  },
  {
    icon: Building,
    title: "About Us",
    content: "Dedicated team of cold email specialists\nFocused on helping B2B companies achieve sales goals\nEffective outreach strategies"
  },
  {
    icon: Calendar,
    title: "What We Do",
    content: "Craft personalized cold email campaigns\nOptimize outreach strategies\nProvide ongoing support to ensure success"
  },
  {
    icon: TrendingUp,
    title: "How We Do It",
    content: "Utilize advanced targeting techniques\nLeverage data-driven insights\nCreate campaigns that resonate with your target audience"
  },
  {
    icon: ArrowUp,
    title: "Our Process",
    content: "Understand your business needs\nDevelop tailored strategy\nExecute campaign\nAnalyze results for continuous improvement"
  },
  {
    icon: DollarSign,
    title: "ROI-Driven Pricing Model",
    content: "Performance-based pricing: We perform or you don't pay\nOur success is tied to your success\nThe more money you make, the more we make\nWe believe in our capabilities and put our money where our mouth is"
  }
];

const problemsData = [
  { title: "Struggling with Lead Generation", description: "Unable to consistently find and engage with qualified prospects" },
  { title: "Insufficient Sales Pipeline", description: "Sales team lacks enough opportunities to meet revenue targets" },
  { title: "Low Meeting Volume", description: "Not booking enough meetings with potential clients" },
  { title: "Poor Lead Quality", description: "Wasting time on unqualified leads that don't convert" },
  { title: "Stagnant Revenue Growth", description: "Unable to scale sales and revenue despite market potential" },
  { title: "Ineffective Outreach Strategies", description: "Current email campaigns fail to resonate with target audience" }
];

export default function LandingPage() {
  const [glitchText, setGlitchText] = useState('The Email Protocol')
  const [currentTestimonial, setCurrentTestimonial] = useState(0)
  const [activeTab, setActiveTab] = useState(0);
  const [activeHowItWorks, setActiveHowItWorks] = useState(0);  // Initialize to 0 (first card)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  useEffect(() => {
    const glitchInterval = setInterval(() => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let result = ''
      for (let i = 0; i < 18; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      setGlitchText(result)
      setTimeout(() => setGlitchText('The Email Protocol'), 100)
    }, 3000)

    return () => clearInterval(glitchInterval)
  }, [])

  const nextTestimonial = useCallback(() => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length)
  }, [])

  const prevTestimonial = useCallback(() => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(nextTestimonial, 3000)
    return () => clearInterval(intervalId)
  }, [nextTestimonial])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('/api/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSubmitMessage('Thank you! Check your email for the PDF link.');
        setEmail('');
      } else {
        setSubmitMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#f7f5ed] text-black font-mono relative">
      <main className="container mx-auto px-4 py-8 sm:py-12">
        {/* Hero section */}
        <section className="text-center mb-12 sm:mb-20">
          <div className="bg-yellow-400 text-black py-2 px-4 mb-6 sm:mb-8 inline-block rounded-full text-base sm:text-lg font-extrabold font-sans tracking-wider transform -rotate-2 vibrate">
            ATTENTION B2B ORGS: COLD EMAIL BROUGHT YOU HERE
          </div>
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6 relative">
            <span className="text-black">{glitchText}</span>
            <span className="absolute top-0 left-0 right-0 text-orange-600 opacity-50 filter blur-sm">
              {glitchText}
            </span>
          </h1>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8">
            We generate 50+ qualified meetings per month for B2B companies using our proprietary AI-driven Email Resonance Engine™
          </p>
          <a 
            href="#contact" 
            className="inline-flex items-center bg-orange-600 text-[#f5f2e8] px-4 sm:px-6 py-2 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-orange-500 transition-colors mb-8 sm:mb-12"
          >
            Explore Partnership <ArrowRight className="ml-2" />
          </a>
          <div className="mb-12 sm:mb-16">
            <button 
              onClick={() => setIsVideoModalOpen(true)}
              className="inline-flex items-center bg-white text-black px-4 sm:px-6 py-2 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-gray-100 transition-all shadow-md border border-gray-300 animate-pulse-glow-scale"
            >
              <Play className="w-5 h-5 sm:w-6 sm:h-6 mr-2 animate-bounce" /> Play Short Video
            </button>
          </div>
        </section>

        {/* Moved section */}
        <section className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 sm:mb-20">
          {[
            { icon: Mail, title: 'High Volume Targeted Outreach', description: 'Precision-engineered campaigns that hit the mark at scale' },
            { icon: Zap, title: 'Lightning Fast', description: 'See tangible results within a week of partnership' },
            { icon: Shield, title: 'Secure & Compliant', description: 'Stay on the right side of spam filters and regulations' },
          ].map((feature, index) => (
            <div 
              key={index} 
              className="bg-[#e8e4d8] p-6 rounded-lg text-center hover:bg-[#d8d4c8] transition-colors cursor-pointer relative overflow-hidden double-border"
              style={{
                boxShadow: '4px 4px 0 0 #000',
                border: '3px solid #000',
                transform: 'translate(-4px, -4px)',
                transition: 'transform 0.2s, box-shadow 0.2s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translate(-2px, -2px)'
                e.currentTarget.style.boxShadow = '2px 2px 0 0 #000'
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translate(-4px, -4px)'
                e.currentTarget.style.boxShadow = '4px 4px 0 0 #000'
              }}
            >
              <feature.icon className="w-12 h-12 mx-auto mb-4 text-orange-600" />
              <h3 className="text-xl font-semibold mb-2 text-orange-500">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </section>

        {/* Common Challenges section */}
        <section className="mb-12 sm:mb-20">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center">Common Challenges We Solve</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {problemsData.map((problem, index) => (
              <div 
                key={index}
                className="bg-[#e8e4d8] p-4 sm:p-6 rounded-lg text-center hover:bg-[#d8d4c8] transition-colors cursor-pointer relative overflow-hidden double-border"
                style={{
                  boxShadow: '4px 4px 0 0 #000',
                  border: '3px solid #ff0000',
                  transform: 'translate(-4px, -4px)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'translate(-2px, -2px)';
                  e.currentTarget.style.boxShadow = '2px 2px 0 0 #000';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'translate(-4px, -4px)';
                  e.currentTarget.style.boxShadow = '4px 4px 0 0 #000';
                }}
              >
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-orange-700">{problem.title}</h3>
                <p className="text-sm sm:text-base text-gray-700">{problem.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-12 sm:mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center text-black">The Email Resonance Engine™ Advantage</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* AI-Powered Precision card */}
            <div 
              className="bg-[#e8e4d8] p-6 rounded-lg relative overflow-hidden double-border cursor-pointer"
              style={{
                boxShadow: '4px 4px 0 0 #000',
                border: '3px solid #000',
                transform: 'translate(-4px, -4px)',
                transition: 'transform 0.2s, box-shadow 0.2s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translate(-2px, -2px)';
                e.currentTarget.style.boxShadow = '2px 2px 0 0 #000';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translate(-4px, -4px)';
                e.currentTarget.style.boxShadow = '4px 4px 0 0 #000';
              }}
            >
              <h3 className="text-2xl font-semibold mb-4 text-orange-600 text-center">AI-Powered Precision</h3>
              <div className="text-center mb-6">
                <Zap className="w-16 h-16 mx-auto text-orange-600 mb-4" />
              </div>
              <ul className="list-none space-y-4">
                {[
                  "Boost revenue with accurate targeting",
                  "Expand sales pipeline with AI-crafted messaging",
                  "Achieve growth goals with data-driven strategies",
                  "Turn cold prospects into interested buyers",
                  "Gain a competitive edge with AI technology"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <Check className="w-5 h-5 text-orange-600 mr-2 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Guaranteed Results card */}
            <div 
              className="bg-[#e8e4d8] p-6 rounded-lg relative overflow-hidden double-border cursor-pointer"
              style={{
                boxShadow: '4px 4px 0 0 #000',
                border: '3px solid #000',
                transform: 'translate(-4px, -4px)',
                transition: 'transform 0.2s, box-shadow 0.2s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translate(-2px, -2px)';
                e.currentTarget.style.boxShadow = '2px 2px 0 0 #000';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translate(-4px, -4px)';
                e.currentTarget.style.boxShadow = '4px 4px 0 0 #000';
              }}
            >
              <h3 className="text-2xl font-semibold mb-4 text-orange-600 text-center">Guaranteed Results</h3>
              <div className="text-center mb-6">
                <TrendingUp className="w-16 h-16 mx-auto text-orange-600 mb-4" />
              </div>
              <ul className="list-none space-y-4">
                {[
                  "Increase qualified leads and revenue",
                  "Close more high-value deals",
                  "Accelerate progress towards revenue targets",
                  "Strengthen your industry position",
                  "Experience sustainable growth"
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <Check className="w-5 h-5 text-orange-600 mr-2 flex-shrink-0" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-12 sm:mb-20">
          <h2 className="text-4xl font-bold mb-12 text-center text-black">Success Stories & Case Studies</h2>
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="group bg-gradient-to-br from-orange-100 to-yellow-100 p-6 rounded-2xl shadow-lg transition-all duration-300 transform hover:-translate-y-2 hover:shadow-xl active:translate-y-0 active:shadow-md cursor-pointer relative overflow-hidden"
                style={{
                  boxShadow: '-4px 4px 0 0 #000',
                  border: '3px solid #000',
                  transform: 'translate(4px, -4px)',
                }}
                onClick={() => window.open(testimonial.caseStudyLink, '_blank')}
              >
                <div className="absolute inset-0 bg-white rounded-2xl transition-transform duration-300 group-hover:translate-x-1 group-hover:translate-y-1 group-active:translate-x-0 group-active:translate-y-0" 
                     style={{ transform: 'translate(-8px, 8px)', zIndex: -1, border: '3px solid #000' }}></div>
                <div className="absolute top-0 right-0 w-24 h-24 bg-orange-400 rounded-bl-full z-0 opacity-50"></div>
                <div className="flex flex-col items-center relative z-10">
                  <testimonial.icon className="w-20 h-20 text-orange-600 mb-4" />
                  <h3 className="text-xl font-semibold text-orange-600 mb-2">{testimonial.name}</h3>
                  <p className="text-orange-500 mb-4 text-sm font-medium">{testimonial.company}</p>
                  <p className="text-gray-900 text-center mb-6 italic">"<span className="text-orange-800 font-semibold">{testimonial.quote}</span>"</p>
                  <span
                    className="mt-auto inline-block bg-orange-500 text-white px-6 py-2 rounded-full font-bold group-hover:bg-black group-hover:text-white border-2 border-black transition-all duration-300 text-sm shadow-md"
                  >
                    Read Case Study
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* New section about believing in cold email again */}
        <section className="mb-12 sm:mb-20 bg-orange-600 text-white p-8 rounded-lg relative overflow-hidden double-border" style={{
          boxShadow: '4px 4px 0 0 #000',
          border: '3px solid #000',
          transform: 'translate(-4px, -4px)',
        }}>
          <h2 className="text-3xl font-bold mb-6 text-center">Believe in Cold Email Again</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">Cold Email: The Misunderstood Giant</h3>
              <p className="mb-4">Many have lost faith in cold email, but it remains one of the most powerful tools for B2B growth. When done right, it's not just effective—it's transformative.</p>
              <p>Our approach reignites the potential of cold email, turning skeptics into believers and leads into long-term partnerships.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Why Cold Email Still Reigns Supreme</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>Direct access to decision-makers</li>
                <li>Highly scalable outreach</li>
                <li>Precise targeting capabilities</li>
                <li>Measurable ROI and performance</li>
                <li>Ability to start meaningful conversations</li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <a 
              href="#contact" 
              className="inline-flex items-center bg-white text-orange-600 px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-100 transition-colors"
            >
              Explore Partnership <ArrowRight className="ml-2" />
            </a>
          </div>
        </section>

        <section className="mb-12 sm:mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">How It Works</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {whyUsData.map((item, index) => (
              <div
                key={index}
                className={`bg-[#e8e4d8] p-4 rounded-lg cursor-pointer transition-all duration-300 double-border ${
                  activeHowItWorks === index ? 'sm:col-span-2 lg:col-span-3' : ''
                }`}
                style={{
                  boxShadow: '4px 4px 0 0 #000',
                  border: '3px solid #000',
                  transform: 'translate(-4px, -4px)',
                }}
                onClick={() => setActiveHowItWorks(index === activeHowItWorks ? null : index)}
              >
                <div className="flex items-center mb-2">
                  <item.icon className="w-6 h-6 text-orange-600 mr-2 flex-shrink-0" />
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                </div>
                {activeHowItWorks === index && (
                  <p className="text-gray-700 mt-4">
                    {item.content.split('\n').map((line, i) => (
                      <span key={i} className="block mb-2">
                        • {line}
                      </span>
                    ))}
                  </p>
                )}
              </div>
            ))}
          </div>
        </section>

        <section id="contact" className="text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">Interested in Collaboration?</h2>
          <p className="text-lg sm:text-xl mb-6">Let's explore if we're the right fit for each other.</p>
          
          <p className="text-base sm:text-lg font-semibold text-orange-700 mb-4">
            Get our "Top 10 Email Strategies for Booking More Meetings" guide instantly!
          </p>
          
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <input 
              type="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email" 
              className="w-full mb-4 px-4 py-2 bg-[#e8e4d8] border border-orange-600 rounded focus:outline-none focus:ring-2 focus:ring-orange-600 text-black"
              required
            />
            <button 
              type="submit" 
              className="w-full bg-yellow-400 text-black px-4 sm:px-6 py-2 sm:py-3 rounded-full text-base sm:text-lg font-extrabold font-sans tracking-wider hover:bg-yellow-300 transition-colors"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'SENDING...' : 'EXPLORE PARTNERSHIP'}
            </button>
          </form>
          
          {submitMessage && (
            <p className="mt-4 text-sm sm:text-base font-bold text-orange-600">
              {submitMessage}
            </p>
          )}
          
          <p className="text-sm sm:text-base font-bold text-black mt-4">
            Plus, we'll send you a link to schedule a personalized strategy session.
          </p>
        </section>
      </main>

      <footer className="bg-[#e8e4d8] py-6 text-center mt-12 sm:mt-20">
        <p className="text-sm sm:text-base text-gray-700">&copy; 2024 The Email Protocol. All rights reserved.</p>
        <p className="text-sm sm:text-base text-orange-600 mt-2">emailprotocol.net</p>
      </footer>

      {/* Video Modal */}
      {isVideoModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl">
            <div className="relative pt-[56.25%]">
              <iframe 
                src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
                frameBorder="0" 
                allow="autoplay; encrypted-media" 
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
            <div className="p-4">
              <button 
                onClick={() => setIsVideoModalOpen(false)}
                className="w-full bg-orange-600 text-white py-2 rounded hover:bg-orange-500 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <CalendlyButton />
    </div>
  )
}